import React from 'react';
import { Navbar, Hero, Standards, Benefits, Services, Reviews, Prices, PriceList, Questions, Contact, Footer } from './components';
import './App.css';
import { HelmetProvider } from 'react-helmet-async';

const App = () => {
  return (
   
<main>
  <HelmetProvider>

    <title>Ambulanta DACMED - Drumul către sănătate începe cu noi</title>
    <meta name="description" content="Servicii medicale la domiciliu, recoltare analize si administrare tratament la indicatia medicului. Drumul catre sanatate incepe cu noi!"/>

    <meta itemprop="name" content="Ambulanta DACMED - Drumul către sănătate începe cu noi"/>
    <meta itemprop="description" content="Servicii medicale la domiciliu, recoltare analize si administrare tratament la indicatia medicului. Drumul catre sanatate incepe cu noi!"/>
    <meta itemprop="image" content="./favicon.ico"/>

    <meta property="og:url" content="http://ambulantadacmed.ro"/>
    <meta property="og:type" content="website"/>
    <meta property="og:title" content="Ambulanta DACMED - Drumul către sănătate începe cu noi"/>
    <meta property="og:description" content="Servicii medicale la domiciliu, recoltare analize si administrare tratament la indicatia medicului. Drumul catre sanatate incepe cu noi!"/>
    <meta property="og:image" content="./favicon.ico"/>

    <meta name="twitter:card" content="summary_large_image"/>
    <meta name="twitter:title" content="Ambulanta DACMED - Drumul către sănătate începe cu noi"/>
    <meta name="twitter:description" content="Servicii medicale la domiciliu, recoltare analize si administrare tratament la indicatia medicului. Drumul catre sanatate incepe cu noi!"/>
    <meta name="twitter:image" content="./favicon.ico"/>
  </HelmetProvider>



<div className='App'>
<div className='app__navbar'>
  <Navbar />
</div>
<Hero />
<Standards/>
<Benefits/>
<Services/>
<Prices/>
<PriceList/>
<Reviews/>
<Questions/>
<Contact/>
<Footer/>
</div>
</main>

   
    
  )
}

export default App